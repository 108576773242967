import { useState, useEffect, useCallback, memo, useRef } from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import dynamic from "next/dynamic";
import { GOOGLE_LOGIN } from "constants/loginConstants";
import { useSession } from "next-auth/react";
import { GenerateUniqId } from "utils/deviceIdGenerator";
import * as actions from "../../redux/actions/authActions";

const OneTapGoogleSignIn = dynamic(() => import("components/auth/oneTapGoogleSignIn"), { ssr: false });
const Header = dynamic(() => import("../shared/header"), { ssr: false });
const AmpHeader = dynamic(() => import("../shared/header/ampHeader"), { ssr: false });
const AmpFooter = dynamic(() => import("../shared/footer/AmpFooter"), { ssr: false });
const Footer = dynamic(() => import("../shared/footer"), {
  ssr: false,
  loading: () => <p>Loading Footer...</p>,
});
const MobileMenuBottom = dynamic(() => import("../shared/footer/bottomMenu"), {
  ssr: false,
  loading: () => <p>Loading Footer...</p>,
});
const PopupModal = dynamic(() => import("../shared/modal/popupModal"), { ssr: false });
const Loader = dynamic(() => import("../shared/loader/transparentLoader"), { ssr: false });

const Layout = ({ Component, pageProps , loadOnScroll }) => {
  const router = useRouter();
  const { data: session } = useSession();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [splashLoader, setSplashLoader] = useState(false);

  const handleRouteChangeStart = useCallback(() => {
    setLoading(true);
    setSplashLoader(true);
    dispatch({ type: "ROUTE_CHANGED", payload: true });
    if (window && typeof window.googletag !== "undefined") {
      window.googletag.cmd.push(() => window.googletag.destroySlots());
    }
    if (window && typeof window._taboola !== "undefined") {
      window._taboola.push({ flush: true });
    }
  }, [dispatch]);

  const handleRouteChangeComplete = useCallback(() => {
    setLoading(false);
    setSplashLoader(false);
    dispatch({ type: "ROUTE_CHANGED", payload: false });
  }, [dispatch]);

  useEffect(() => {
    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router, handleRouteChangeStart, handleRouteChangeComplete]);

  useEffect(() => {
    const timer = setTimeout(() => setSplashLoader(false), 500); // Reduced delay
    return () => clearTimeout(timer);
  }, []);

  const responseGoogle = useCallback(() => {
    GenerateUniqId().then((deviceId) => {
      const deviceToken = localStorage.getItem("deviceToken");
      const loginObj = {
        loginType: GOOGLE_LOGIN,
        deviceType: "web",
        deviceId,
        deviceToken,
        facebookId: process.env.NEXT_PUBLIC_FACEBOOK_ID,
        googleId: session?.user?.uniqueId,
        appleId: "",
        fullname: session?.user?.name,
        email: session?.user?.email,
        GoogleToken: session?.id_token,
        profileImage: session?.user?.image,
      };
      dispatch(actions.LoggedIn(loginObj));
    });
  }, [dispatch, session?.user?.email, session?.user?.uniqueId, session?.user?.name, session?.id_token, session?.user?.image]);

  useEffect(() => {
    if (session?.user && !JSON.parse(localStorage.getItem("lallantopSession2"))?.id) {
      responseGoogle();
    }
  }, [session, responseGoogle]);

  useEffect(() => {
    const category = router?.pathname?.includes("/text") ? "text" :
      router?.pathname?.includes("/video") && !router?.pathname?.includes("/video/") ? "video" : "";
    localStorage.setItem("categoryTextOrVideo", JSON.stringify(category));
  }, [router]);


  function LazyFooter() {
    const [isVisible, setIsVisible] = useState(false);
    const footerRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once visible
        }
      });

      if (footerRef.current) {
        observer.observe(footerRef.current);
      }

      return () => observer.disconnect();
    }, []);

    return (
      <div ref={footerRef}>
        {isVisible && <Footer />}
      </div>
    );
  }

  return (
    <>
      <PopupModal />
      {loading && <Loader />}
      <OneTapGoogleSignIn />

      <div
        style={{
          display: splashLoader && router.pathname === "/" ? "" : "none",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <img
          src="https://static.thelallantop.com/images/post/1690876464482_frame.webp?width=200"
          width="170px"
          height="170px"
          loading="lazy"
          alt="The Lallantop"
        />
      </div>

      <div
        style={{
          opacity: splashLoader && router.pathname === "/" ? 0 : 1,
          pointerEvents: splashLoader && router.pathname === "/" ? "none" : ""
        }}
      >
        {!router.pathname.includes("/jwplayer") &&
          !router.pathname.includes("/amp") &&
          !router.pathname.includes("/election-widgets") &&
          !router.pathname.includes("lallantop-adda/terms-and-conditions") &&
          <Header />}
        {router.pathname.includes("/amp") && <AmpHeader />}

        <Component {...pageProps} />


        

        {!router.pathname.includes("/jwplayer") &&
          !router.pathname.includes("/amp") &&
          !router.pathname.includes("/election-widgets") &&
          !router.pathname.includes("lallantop-adda/terms-and-conditions") &&
          <>
            <LazyFooter />
            <MobileMenuBottom />
          </>

        }
        {loadOnScroll && router.pathname.includes("/amp") && <AmpFooter />}
      </div>
    </>
  );
};

export default memo(Layout);