import {
    SERIES_GET_FAILED,
    SERIES_GET_INIT,
    SERIES_GET_SUCCESS,
    SERIES_FOLLOWED_INIT,
    SERIES_FOLLOWED_SUCCESS,
    SERIES_FOLLOWED_FAILED,
    SERIES_GET_BY_ID_SUCCESS,
    SERIES_GET_BY_ID_FAILED,
    SERIES_GET_BY_ID_INIT,
    SET_SCOREDATA
  } from "redux/actionTypes/series.actionTypes";
  
  const iniitialState = {
    seriesData: {},
    error: false,
    message: "",
    seriesById: {},
    scoreData : null
  };
  
  export default function SeriesReducer(state = iniitialState, action) {
  
    switch (action.type) {
      // Do something here based on the different types of actions

      case SET_SCOREDATA:
        return {
          ...state,
          scoreData : action.payload
        }
  
      case SERIES_GET_INIT:
        return {
          ...state,
          ...action.payload,
        };
      case SERIES_GET_SUCCESS:
        return {
          ...state,
          ...action.payload,
          seriesData: action.payload.entity,
          error: false,
        };
      case SERIES_GET_FAILED:
        return {
          ...state,
          ...action.payload,
          message: action.payload.message,
          error: true,
        };

        case SERIES_GET_BY_ID_INIT:
        return {
          ...state,
          ...action.payload,
        };
  
      case SERIES_GET_BY_ID_SUCCESS:
        return {
          ...state,
          ...action.payload,
          seriesById: action.payload.entity,
          error: false,
        };
      case SERIES_GET_BY_ID_FAILED:
        return {
          ...state,
          ...action.payload,
          message: action.payload.message,
          error: true,
        };

        case SERIES_FOLLOWED_INIT:
          return {
            ...state,
            ...action.payload,
            message: "fetching data",
            loading: true,
          };
    
        case SERIES_FOLLOWED_SUCCESS:
          return {
            ...state,
            message: "Updated Successfully!",
            loading: false,
            error: false,
          };
        case SERIES_FOLLOWED_FAILED:
          return {
            ...state,
            message: "Failed to fetch",
            error: true,
            loading: false,
          };
  
      default:
        // If this reducer doesn't recognize the action type, or doesn't
        // care about this specific action, return the existing state unchanged
        return state;
    }
  }
  