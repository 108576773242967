
export const SERIES_GET_INIT = "SERIES_GET_INIT";
export const SERIES_GET_SUCCESS = "SERIES_GET_SUCCESS";
export const SERIES_GET_FAILED = "SERIES_GET_FAILED";

export const SERIES_GET_BY_ID_INIT = "SERIES_GET_BY_ID_INIT";
export const SERIES_GET_BY_ID_SUCCESS = "SERIES_GET_BY_ID_SUCCESS";
export const SERIES_GET_BY_ID_FAILED = "SERIES_GET_BY_ID_FAILED";

// for series followed actions
export const SERIES_FOLLOWED_INIT = "SERIES_FOLLOWED_INIT";
export const SERIES_FOLLOWED_SUCCESS = "SERIES_FOLLOWED_SUCCESS";
export const SERIES_FOLLOWED_FAILED = "SERIES_FOLLOWED_FAILED";

//to store the score data object in redux
export const SET_SCOREDATA = 'SET_SCOREDATA'